// extracted by mini-css-extract-plugin
export var aIframe = "a";
export var aIframeChat = "b";
export var aTagEntry = "c";
export var aTagPayment = "d";
export var aTagClose = "e";
export var aTagAnnounce = "f";
export var aTagLottery = "g";
export var aTagFree = "h";
export var lArticle = "i";
export var lArticle__inner = "j";
export var lArticle__header = "k";
export var aTitle = "l";
export var aDate = "m";
export var lArticle__thumbnail = "n";
export var lArticle__content = "o";
export var lArticle__content__info = "p";
export var animationMarquee = "q";