import React from "react"
import { graphql } from "gatsby"
import { Image } from "@atoms/Image"
// import Prism from '@atoms/Prism'
import SEO from "@atoms/Seo"
import * as Spacer from "@atoms/Spacer"
import { Section, SectionHeader, SectionContent, SectionColumn, SectionMoreLink } from "@layouts/Section"
import Iframe from 'react-iframe'

import { Router } from "@reach/router"
import PrivateRoute from "@projects/PrivateRoute"

import * as css from "@css/layouts/l-article.module.styl"

// import { gsap } from "gsap"
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger)

const Content = ({ title, date, content, thumbnail, thumbnailFull, acf_yutoyou }) => {
    const { vimeoUrl, vimeoChatUrl } = acf_yutoyou
    return(
        <article className={css.lArticle}>
            <div className={css.lArticle__inner}>
                <header className={css.lArticle__header}>
                    <p className={css.aDate}><time dateTime={date}>{date}</time></p>
                    <h1 className={css.aTitle}>{title}</h1>
                </header>
                {vimeoUrl ?
                    <div className={css.aIframe}>
                        <Iframe url={`${vimeoUrl}`} scrolling={false} allow="autoplay; fullscreen; picture-in-picture" allowfullscreen/>
                    </div>
                    :
                    thumbnailFull && <figure className={css.lArticle__thuombnail}>
                        <Image data={thumbnailFull.node.localFile} alt="thubmanil" />
                    </figure>
                }
                {
                    vimeoChatUrl &&
                        <div className={css.aIframeChat}>
                            <Iframe url={`${vimeoChatUrl}`} scrolling={false} allow="autoplay; fullscreen; picture-in-picture" allowfullscreen />
                        </div>
                }
                <div className={css.lArticle__content} dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </article>
    )
}

function YuToYouPage({ data }) {
    console.log(data)
    const { yutoyou } = data
    const { title, date, content, thumbnail, thumbnailFull, acf_yutoyou } = yutoyou
    const { vimeoUrl } = acf_yutoyou

    // const img = getImage(thumbnail.gatsbyImageData)
    // const contentRef = React.useRef()
    return (
        <>
            <SEO
                subtitle={title}
                // description={excerpt}
                image={thumbnailFull && `https://${thumbnailFull.node.localFile.publicURL}`}
            />
            <Spacer.Header />
            <Section>
                <SectionHeader
                    title="YUtoYOU"
                    text="動画配信＆生配信"
                    isBorder={false}
                />
                <Router basepath="/member/yu-to-you">
                    <PrivateRoute path="/:id" component={Content} {...yutoyou} />
                </Router>
                <SectionMoreLink
                    title="More"
                    text="動画配信＆生配信一覧"
                    to="/member/yu-to-you/"
                    isBorder={false}
                />
            </Section>
        </>
    )
}

export const pagerQuery = graphql`
    fragment YuToYou on WpYuToYou {
        acf_yutoyou {
            vimeoUrl
            vimeoChatUrl
        }
        slug
        date(formatString: "YYYY.MM.DD")
        title
        content
        databaseId
        thumbnail: featuredImage {
            node {
                localFile {
                    publicURL
                    childImageSharp {
                        gatsbyImageData(
                            width: 500
                            height: 280
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                            quality: 100
                        )
                    }
                }
            }
        }
        thumbnailFull: featuredImage {
            node {
                localFile {
                    publicURL
                    childImageSharp {
                        gatsbyImageData(
                            width: 960
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                            quality: 100
                        )
                    }
                }
            }
        }
    }
    query YuToYouBySlug($id: String!){
        yutoyou : wpYuToYou( id: { eq: $id }){
            ...YuToYou
        }
    }
`
export default YuToYouPage